@import '../../../../styles/customMediaQueries.css';

.root {
}

.rootTerms {
  display: block;
  
  @media (--viewportMedium) {
    display: none;
  }
}

@import '../../../../styles/customMediaQueries.css';

.root {
  /* fallback option */
  word-break: break-all;
  /* use break-word if available */
  word-break: break-word;
  hyphens: auto;
  
  @media (--viewportLarge) {
    padding-left: 68px;
  }

  @media (--viewport1280) {
    padding-left: 72px;
  }
}

.media {
  width: 100%;
  border-radius: 8px;
  margin-bottom: 0;
}

.text {
  width: 100%;

  &:first-child {
    margin-top: 0;
  }

  /* we style global elements only because they are generated by markdown processor */
  & a {
    color: var(--colorWhite);
    white-space: nowrap;
    font-size: 12px;
    line-height: normal;

    @media (--viewportMedium) {
      font-size: 14px;
    }

    &:hover {
      color: var(--marketplaceColor);
      text-decoration: none;
    }
  }

  & ol {
    margin-top: 0px;
    padding-left: 0px;
  }

  & li {
    font-size: 14px;
    color: var(--colorGrey700);
    padding: 0px;
    margin: 0 0 8px;
    list-style-position: inside;

    @media (--viewportMedium) {
      font-size: 16px;
    }

    &:last-child {
      margin: 0;
    }
  }

  & p {
    padding: 0px;
    margin: 0 0 20px;
    color: var(--colorWhite);
    font-weight: var(--fontWeightMedium);
    font-size: 14px;
    line-height: normal;
    
    @media (--viewportMedium) {
      font-size: 16px;
    }
  }
}
